<script>
import Spinner from './spinner.vue'

export default {
  extends: Spinner,
  props: {
    useModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
